<template>
	<footer class="default-footer col" align-x="center">
		<div class="col" style="overflow:hidden;">
			<img src="@/assets/logo-white.png" width="165">
			<br>
			<br>
			<div class="row-grid" gutter-x="40" gutter-y="20">
				<div class="col" gutter="10">
					<router-link to="/about" class="white">關於金枝</router-link>
					<router-link to="/about">關於金枝演社</router-link>
					<router-link to="/about/founder">創辦人暨藝術總監</router-link>
					<router-link to="/about/mother">金枝的阿母</router-link>
					<router-link to="/#timeline" @click.native="handleClick()">年表大事紀</router-link>
				</div>
				<div class="col" gutter="10">
					<router-link to="/activity" class="white">最新活動</router-link>
					<router-link to="/news" class="white">焦點消息</router-link>
					<router-link to="/portfolio" class="white">系列作品</router-link>
				</div>
				<div class="col" gutter="10">
					<router-link to="/perform" class="white">金枝走演</router-link>
					<router-link to="/perform/start">緣起</router-link>
					<router-link to="/perform/promoter">發起人的話</router-link>
					<router-link to="/perform/director">導演的話</router-link>
					<router-link to="/perform/event">場次紀錄</router-link>
					<router-link to="/perform/support">支持走演</router-link>
				</div>
				<div class="col" gutter="10">
					<router-link to="/join" class="white">加入金枝</router-link>
					<router-link to="/join/member">金粉會員</router-link>
					<router-link to="/join/dornor">金枝椅友</router-link>
					<router-link to="/join/volunteer">金枝志工</router-link>
				</div>
				<div class="col" gutter="10">
					<router-link to="/product" class="white">金枝百貨舖</router-link>
					<router-link to="/product">商品總覽</router-link>
				</div>
			</div>
			<br>
			<br>
			<div class="col" gutter="10">
				<p class="white">聯絡我們</p>
				<p>TEL: <a href="tel:+886-2-6637-7987"><u>(+886)-2-6637-7987</u></a></p>
				<p>Fax: (+886)-2-2618-6842</p>
				<p>E-mail: <a href="mailto:gbt1993@gmail.com"><u>gbt1993@gmail.com</u></a></p>
				<p>24945 臺灣新北市八里區龍米路二段117巷39-1號</p>
				<small>No.39-1, Lane. 117, Sec. 2, Longmi Rd., Bali Dist., New Taipei City 24945, Taiwan</small>
				<br>
				<follow-us label-visible white />
			</div>
		</div>
		<br>
		<br>
		<br>
		<div class="col" align-x="center" gutter="10" style="text-align:center;">
			<small class="white">Copyright © 2019 Golden Bough Theatre.</small>
			<small class="white">All rights reserved. Design by <a href="https://www.twodot.com.tw" target="_blank"><u>TwoDot 兩打半互動設計</u></a></small>
		</div>
		<br>
	</footer>
</template>

<script>
export default {
	components: {
		FollowUs: () => import("@/components/follow-us")
	},
	methods: {
		handleClick() {
			const el = document.getElementById(location.hash.replace("#", ""));
			if (el) el.scrollIntoView({ behavior: "smooth" });
		}
	}
}
</script>

<style lang="scss" scoped>
.default-footer {
	padding: 5%;
	background: $theme-light;
	background-position: bottom right, bottom left;
	background-repeat: no-repeat;
	background-size: 300px;

	@media screen and (min-width: 769px) {
		background-image: url("../../assets/background/straw1.png"),
			url("../../assets/background/straw2.png");
	}
}

a {
	white-space: nowrap;
}

.white {
	color: #fff;
}

small {
	font-size: smaller;
}
</style>